import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { BsTrash, BsUpload } from 'react-icons/bs';
import ReactImageMagnify from 'react-image-magnify';

import ImageUploadModal from './ImageUploadModal';
import ImageUploaderInputGroup from './ImageUploaderInputGroup';
import ResetImagesModal from '../ResetImagesModal';

import styles from './styles.module.css';

const ImageUploader = ({
  onUpload,
  disabled,
  templateImages,
  productImages,
  onDelete,
  onReset,
}) => {
  const [templateModalVisible, setTemplateModalVisible] = useState(false);
  const collapsed = useSelector((store) => !store.columns.items.show_images);
  const [resetProductModal, setResetProductModal] = useState(false);

  const onImageUpload = (image) => {
    if (onUpload) {
      onUpload(image);
    }
  };

  const onResetProduct = () => {
    setResetProductModal(true);
  };

  const onDeleteImage = (imageId) => {
    if (onDelete) {
      onDelete(imageId);
    }
  };

  const applyCollapsed = (collection) => collection.slice(0, collapsed ? 1 : collection.length);

  return (
    <>
      <ResetImagesModal
        show={resetProductModal}
        onCancel={() => setResetProductModal(false)}
        onConfirm={() => onReset && onReset()}
      />
      <div className={styles.container}>
        {applyCollapsed(productImages).map((image, idx) => (
          <div key={image.id}>
            {idx === 0 && (
              <ImageUploaderInputGroup
                withImage
                disabled={disabled}
                buttons={[{ hint: 'Reset the images', icon: <BsTrash />, onClick: onResetProduct }]}
              />
            )}
            <ReactImageMagnify
              smallImage={{
                alt: 'main',
                src: image.url,
              }}
              largeImage={{
                src: image.medium_url || image.url,
                height: 500,
              }}
              enlargedImagePosition="over"
              enlargedImageContainerStyle={{
                zIndex: 4,
              }}
              imageClassName={styles.image}
            />
          </div>
        ))}

        {applyCollapsed(templateImages).map((image, idx) => (
          <div key={image.id}>
            <ImageUploaderInputGroup
              withImage
              disabled={disabled}
              buttons={[
                { hint: 'Delete the image', icon: <BsTrash />, onClick: () => onDeleteImage(image.id) },
                idx === 0 && { hint: 'Upload Image File', icon: <BsUpload />, onClick: () => setTemplateModalVisible(true) },
              ]}
            />
            <ReactImageMagnify
              smallImage={{
                alt: 'main',
                src: image.url,
                // isFluidWidth: true,
              }}
              largeImage={{
                src: image.medium_url || image.url,
                width: 600,
              }}
              enlargedImageContainerStyle={{
                zIndex: 4,
              }}
              enlargedImagePosition="over"
              imageClassName={styles.image}
            />
          </div>
        ))}

        {!productImages.length && !templateImages.length && (
          <ImageUploaderInputGroup
            disabled={disabled}
            buttons={[
              { hint: 'Upload Image File', icon: <BsUpload />, onClick: () => setTemplateModalVisible(true) },
            ]}
          />
        )}
      </div>

      <ImageUploadModal
        show={templateModalVisible}
        onClose={() => setTemplateModalVisible(false)}
        onUpload={onImageUpload}
      />
    </>
  );
};

ImageUploader.propTypes = {
  onUpload: PropTypes.func,
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
  onReset: PropTypes.func,
  productImages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
  templateImages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
};

ImageUploader.defaultProps = {
  onUpload: null,
  disabled: false,
  onDelete: null,
  onReset: null,
};

export default ImageUploader;
